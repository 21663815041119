@import "@dg/common/styles/common/function.css";

@tailwind components;

@layer components {
	.gradiant {
		@apply border-0 bg-gradient-to-r from-dg-livePurple to-[#b03bf8] text-dg-white dark:text-dgdark-white;

		&-90 {
			@apply border-0 bg-gradient-to-r from-dg-livePurple/90 to-[#b03bf8]/90 text-dg-white dark:text-dgdark-white;
		}
	}
}
